import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">GreenBridge Africa</h3>
            <p className="text-gray-400">
              Making carbon markets work for African farmers.
            </p>
            <div className="mt-4 space-y-2">
              <div className="flex items-center gap-2">
                <Mail className="h-5 w-5" />
                <span>contact@greenbridge.africa</span>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="h-5 w-5" />
                <span>+256 777 429 026</span>
              </div>
            </div>
          </div>

          <div>
            <h4 className="font-bold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><a href="#marketplace">Carbon Marketplace</a></li>
              <li><a href="#cooperatives">Cooperatives</a></li>
              <li><a href="#farmers">Farmers</a></li>
              <li><a href="#impact">Impact</a></li>
            </ul>
          </div>

          <div>
            <h4 className="font-bold mb-4">Resources</h4>
            <ul className="space-y-2">
              <li><a href="#">How It Works</a></li>
              <li><a href="#">FAQ</a></li>
              <li><a href="#">Documentation</a></li>
              <li><a href="#">Support</a></li>
            </ul>
          </div>

          <div>
            <h4 className="font-bold mb-4">Join Our Community</h4>
            <div className="flex flex-col gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded bg-gray-800 border border-gray-700"
              />
              <button className="bg-green-700 text-white px-4 py-2 rounded">
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
          <p>© 2024 GreenBridge Africa. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}