import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Leaf, Users, Sun, DollarSign } from 'lucide-react';

export default function ImpactMetrics() {
  const monthlyData = [
    { month: 'Jan', credits: 1200, farmers: 800 },
    { month: 'Feb', credits: 1800, farmers: 950 },
    { month: 'Mar', credits: 2400, farmers: 1100 },
    { month: 'Apr', credits: 3200, farmers: 1300 },
    { month: 'May', credits: 4000, farmers: 1500 }
  ];

  const impactStats = [
    {
      icon: Leaf,
      value: "25,000+",
      label: "Carbon Credits Generated",
      color: "text-green-600"
    },
    {
      icon: Users,
      value: "1,500+",
      label: "Farmers Enrolled",
      color: "text-blue-600"
    },
    {
      icon: Sun,
      value: "1,000+",
      label: "Solar Systems Installed",
      color: "text-yellow-600"
    },
    {
      icon: DollarSign,
      value: "$500K+",
      label: "Farmer Earnings",
      color: "text-green-600"
    }
  ];

  return (
    <section className="py-16 bg-gray-50" id="impact">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Our Impact</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Tracking our progress in connecting African farmers to carbon markets.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          {impactStats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div key={index} className="bg-white rounded-lg shadow p-6 text-center">
                <Icon className={`h-8 w-8 ${stat.color} mx-auto mb-4`} />
                <div className="text-2xl font-bold mb-2">{stat.value}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            );
          })}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-bold mb-6">Growth Metrics</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={monthlyData}>
                <XAxis dataKey="month" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="credits"
                  stroke="#16a34a"
                  name="Carbon Credits"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="farmers"
                  stroke="#2563eb"
                  name="Farmers"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </section>
  );
}