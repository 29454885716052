import React from 'react';
import { User, Sun, Leaf, DollarSign } from 'lucide-react';

export default function FarmerSection() {
  const farmerStories = [
    {
      name: "Sarah Namukasa",
      location: "Mubende, Uganda",
      story: "Started with solar installation, now earning from both clean energy and tree credits.",
      impact: {
        trees: 200,
        earnings: 450,
        solar: true
      }
    },
    {
      name: "John Muwonge",
      location: "Mityana, Uganda",
      story: "Transformed 2 hectares into an agroforestry system, increasing farm income by 40%.",
      impact: {
        trees: 300,
        earnings: 600,
        solar: true
      }
    }
  ];

  return (
    <section className="py-16" id="farmers">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Farmer Success Stories</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Meet the farmers transforming their land and livelihoods through carbon credits.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {farmerStories.map((farmer, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-start gap-4">
                <div className="bg-green-100 p-3 rounded-full">
                  <User className="h-6 w-6 text-green-700" />
                </div>
                <div>
                  <h3 className="font-bold text-lg">{farmer.name}</h3>
                  <p className="text-gray-600">{farmer.location}</p>
                </div>
              </div>

              <p className="mt-4 text-gray-600">{farmer.story}</p>

              <div className="grid grid-cols-3 gap-4 mt-6">
                <div className="text-center">
                  <Leaf className="h-6 w-6 text-green-600 mx-auto mb-2" />
                  <p className="font-bold">{farmer.impact.trees}</p>
                  <p className="text-sm text-gray-600">Trees</p>
                </div>
                <div className="text-center">
                  <DollarSign className="h-6 w-6 text-green-600 mx-auto mb-2" />
                  <p className="font-bold">${farmer.impact.earnings}</p>
                  <p className="text-sm text-gray-600">Monthly</p>
                </div>
                <div className="text-center">
                  <Sun className="h-6 w-6 text-green-600 mx-auto mb-2" />
                  <p className="font-bold">{farmer.impact.solar ? 'Yes' : 'No'}</p>
                  <p className="text-sm text-gray-600">Solar</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-8">
          <button className="bg-green-700 text-white px-6 py-3 rounded-lg">
            Join Our Farmer Network
          </button>
        </div>
      </div>
    </section>
  );
}