import React from 'react';
import { Menu } from 'lucide-react';

export default function Navigation() {
  return (
    <nav className="bg-white border-b sticky top-0 z-50">
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold text-green-700">GreenBridge Africa</div>
          
          {/* Mobile menu button */}
          <button className="md:hidden">
            <Menu className="h-6 w-6" />
          </button>

          {/* Desktop menu */}
          <div className="hidden md:flex space-x-6">
            <a href="#marketplace" className="text-gray-600 hover:text-green-700">Marketplace</a>
            <a href="#cooperatives" className="text-gray-600 hover:text-green-700">Cooperatives</a>
            <a href="#farmers" className="text-gray-600 hover:text-green-700">Farmers</a>
            <a href="#impact" className="text-gray-600 hover:text-green-700">Impact</a>
            <button className="bg-green-700 text-white px-4 py-2 rounded-lg hover:bg-green-800">
              Get Started
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}