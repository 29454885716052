import React from 'react';
import { Sun, Leaf, DollarSign, BarChart, Users, ArrowRight } from 'lucide-react';

export default function HowItWorks() {
  const solarProcess = [
    {
      icon: Users,
      title: "Join Through Cooperative",
      description: "Register with your local farming cooperative - no fees required"
    },
    {
      icon: Sun,
      title: "Get Solar System",
      description: "Receive and install your free solar system"
    },
    {
      icon: BarChart,
      title: "Generate Credits",
      description: "Automatic monitoring tracks your clean energy usage"
    },
    {
      icon: DollarSign,
      title: "Earn Monthly",
      description: "Receive payments directly to your mobile money account"
    }
  ];

  const agroforestryProcess = [
    {
      icon: Leaf,
      title: "Plant Trees",
      description: "Receive free seedlings and planting support"
    },
    {
      icon: BarChart,
      title: "Monitor Growth",
      description: "Regular satellite monitoring tracks tree growth"
    },
    {
      icon: DollarSign,
      title: "Generate Credits",
      description: "Trees convert to carbon credits after verification"
    },
    {
      icon: ArrowRight,
      title: "Long-term Income",
      description: "Earn from your trees for years to come"
    }
  ];

  return (
    <section className="py-16 bg-gray-50" id="how-it-works">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">How It Works</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Two ways to earn from carbon credits: Quick Carbon through solar energy
            and Growth Carbon through agroforestry
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {/* Solar Process */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-xl font-bold mb-6 flex items-center gap-2">
              <Sun className="h-6 w-6 text-yellow-500" />
              Quick Carbon: Solar Energy
            </h3>
            
            <div className="space-y-8">
              {solarProcess.map((step, index) => {
                const Icon = step.icon;
                return (
                  <div key={index} className="flex gap-4">
                    <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center">
                      <Icon className="h-5 w-5 text-green-600" />
                    </div>
                    <div>
                      <h4 className="font-bold mb-1">{step.title}</h4>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Agroforestry Process */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-xl font-bold mb-6 flex items-center gap-2">
              <Leaf className="h-6 w-6 text-green-600" />
              Growth Carbon: Agroforestry
            </h3>
            
            <div className="space-y-8">
              {agroforestryProcess.map((step, index) => {
                const Icon = step.icon;
                return (
                  <div key={index} className="flex gap-4">
                    <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center">
                      <Icon className="h-5 w-5 text-green-600" />
                    </div>
                    <div>
                      <h4 className="font-bold mb-1">{step.title}</h4>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}