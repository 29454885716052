import React from 'react';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import HowItWorks from './components/howItWorks';
import Marketplace from './components/Marketplace';
import Cooperatives from './components/Cooperatives';
import FarmerSection from './components/FarmerSection';
import ImpactMetrics from './components/ImpactMetrics';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen">
      <Navigation />
      <Hero />
      <HowItWorks />
      <Marketplace />
      <Cooperatives />
      <FarmerSection />
      <ImpactMetrics />
      <Footer />
    </div>
  );
}

export default App;