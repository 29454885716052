import React from 'react';
import { Search, Filter, Leaf, Users } from 'lucide-react';

const ProjectCard = ({ project }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 border">
    <div className="flex justify-between items-start mb-4">
      <div>
        <h3 className="font-bold text-lg">{project.name}</h3>
        <p className="text-sm text-gray-600">{project.location}</p>
      </div>
      <span className={`px-3 py-1 rounded-full text-sm ${
        project.status === 'Verified' 
          ? 'bg-green-100 text-green-700'
          : 'bg-yellow-100 text-yellow-700'
      }`}>
        {project.status}
      </span>
    </div>
    
    <div className="grid grid-cols-2 gap-4 mb-4">
      <div>
        <p className="text-gray-600 text-sm">Available Credits</p>
        <p className="font-bold">{project.credits} tCO2</p>
      </div>
      <div>
        <p className="text-gray-600 text-sm">Price/Credit</p>
        <p className="font-bold">${project.price}/tCO2</p>
      </div>
      <div>
        <p className="text-gray-600 text-sm">Farmers</p>
        <p className="font-bold">{project.farmers}</p>
      </div>
      <div>
        <p className="text-gray-600 text-sm">Women & Youth</p>
        <p className="font-bold">{project.womenYouth}%</p>
      </div>
    </div>

    <div className="flex justify-between items-center pt-4 border-t">
      <button className="text-green-700 flex items-center gap-2">
        View Details
      </button>
      <button className="bg-green-700 text-white px-4 py-2 rounded-lg">
        Purchase Credits
      </button>
    </div>
  </div>
);

export default function Marketplace() {
  const projects = [
    {
      name: "Mubende Farmers Cooperative",
      location: "Uganda",
      status: "Verified",
      credits: 5000,
      price: 25,
      farmers: 500,
      womenYouth: 60
    },
    {
      name: "Ethiopia Green Initiative",
      location: "Ethiopia",
      status: "Pending",
      credits: 3000,
      price: 22,
      farmers: 300,
      womenYouth: 55
    },
    {
      name: "Nigeria Agroforestry Project",
      location: "Nigeria",
      status: "Verified",
      credits: 4500,
      price: 24,
      farmers: 450,
      womenYouth: 65
    }
  ];

  return (
    <section className="py-16" id="marketplace">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <h2 className="text-3xl font-bold mb-4 md:mb-0">Carbon Credits Marketplace</h2>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative">
              <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search projects..."
                className="pl-10 pr-4 py-2 border rounded-lg w-full md:w-64"
              />
            </div>
            <select className="border rounded-lg px-4 py-2">
              <option value="all">All Regions</option>
              <option value="uganda">Uganda</option>
              <option value="ethiopia">Ethiopia</option>
              <option value="nigeria">Nigeria</option>
            </select>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
}