import React from 'react';
import { ArrowRight, Globe } from 'lucide-react';

export default function Hero() {
  return (
    <section className="bg-gradient-to-r from-green-700 to-green-900 text-white py-20">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Turning African Farming into Climate Action
          </h1>
          <p className="text-xl mb-8">
            Direct access to carbon markets. Zero upfront cost.
            Start earning in 12 months.
          </p>
          
          <div className="grid md:grid-cols-3 gap-4 text-center mb-12">
            <div className="bg-white/10 rounded-lg p-4">
              <div className="text-3xl font-bold">25,000+</div>
              <p>Carbon Credits</p>
            </div>
            <div className="bg-white/10 rounded-lg p-4">
              <div className="text-3xl font-bold">1,500+</div>
              <p>Farmers</p>
            </div>
            <div className="bg-white/10 rounded-lg p-4">
              <div className="text-3xl font-bold">60%</div>
              <p>Women & Youth</p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-4 justify-center">
            <button className="bg-white text-green-700 px-6 py-3 rounded-lg font-bold flex items-center justify-center">
              Join as Farmer
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
            <button className="border border-white px-6 py-3 rounded-lg font-bold">
              Buy Credits
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}